import React from "react"
import { graphql } from "gatsby"
import { generateNameWithHonorific, generatePath } from "../lib/helpers"
import WebsiteMeta from "../components/WebsiteMeta"
import ListElement from "../components/ListElement"
import FadeIn from "../components/FadeIn"
import styled from "styled-components"

export default function People({ data }) {
  const { people } = data

  return (
    <>
      <WebsiteMeta title="People" />
      <FadeIn direction="up">
        <PageTitle>People</PageTitle>
      </FadeIn>

      <FadeIn direction="up" delay={250}>
        <PeopleWrapper>
          {people.edges.map(({ node }, index) => (
            <ListElement
              link={generatePath(node.slug.current, "person")}
              title={generateNameWithHonorific(node.name, node.honorific)}
              index={index + 1 < 10 ? `0${index + 1}` : index + 1}
              key={node._id}
            />
          ))}
        </PeopleWrapper>
      </FadeIn>
    </>
  )
}

export const pageQuery = graphql`
  query {
    people: allSanityPerson(sort: { fields: name, order: ASC }) {
      edges {
        node {
          _id
          honorific
          name
          slug {
            current
          }
        }
      }
    }
  }
`

const PageTitle = styled.h1`
  margin: 0.5rem 0 0 0;
`

const PeopleWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
`
